#survey{

  #survey-save{
    text-align: center;
  }

  .row{
    margin-bottom: 20px;

    .card{
      width: 96%;
      margin: 0 2%;

      .survey-area > .card-header{
        color: #fff;
        font-weight: bold;
      }
    }

    .survey-area{
      margin: 0px;
      margin-bottom: 20px;
      width: 100%;
      border: 0;

      .status-survey-area{
        border: 1px solid #ccc;
        border-radius: 20px 20px 0 0;
        padding: 2px 15px;
        background-color: #fff;
      }

      figure.survey-question-fleg {
        float: left;       
        margin-right: 5px;

        &.selected{ background-color: #28a746; }
      }
    }
  }

  .survey-area{
    .accordion .card{
      width: 100%;
      margin: 0;
    }
  }

}
