#home{
  .row{
    margin-bottom: 20px;

    .card{
      width: 96%;
      margin: 0 2%;
    }
  }

  #DatePickerContainer{
    margin-bottom: 15px;
    position: relative;
    bottom: 6px;

    .react-datepicker-wrapper{
      float: left;
      input {
        border: 1px solid #ddd;
        margin-right: 15px;
        /* margin-bottom: 15px; */
        padding: 6px;
        border-radius: 4px;
      }
    }
  }

  #historyTable{
      thead th{ text-align: center; }
      tbody td{ vertical-align: middle; }
  }

  figure.circle{
    &.completed{
      background: #28a746;
    }
    &.notCompleted{
      background: #ffec02;
    }
  }
}
