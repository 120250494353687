body {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #8bba14;
  background-image: url("../images/background-finmed.png");
  background-repeat: no-repeat;
}

#topBar{
  border-bottom: 3px solid #28a745;
  box-shadow: 0 0 10px #888;
}

.pageContainer{
    margin-top: 70px;
}

.container {
  &.container-marginTop{
    margin-top: 10%;
  }

  .row{
    .col{
      &.logo{
        margin: 0 auto;
        text-align: center;
        padding: 20px;
      }
    }
  }
}

.loader{
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(173, 181, 189, 0.5);
  z-index: 1000;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    position: fixed; /* or absolute */
    top: 30%;
    left: 46.5%;

  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.tooltip-inner {
  max-width: 50vw;
  width: 100%;
  color: #000;
  background-color: #8bba14;
  font-size: .7rem;
}

figure.circle{
  display: block;
  background: #e0e0e0;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  margin: 0;
  border: 1px solid #bfbfbf;
}
