#graph{
  .highlights{
    margin: 10px auto;    
    font-size: .8rem;
    padding-bottom: 10px;
    background-color: #fff;

    .col p{
      margin: 0;
    }

    .highlights-green{ background-color: rgba(0, 128, 0, 0.30) }
    .highlights-enough{ background-color: rgba(128, 128, 128, 0.51) }
    .highlights-misses{ background-color: rgba(255, 165, 0, 0.51) }

    .highlights-head{
      color: #fff;
      text-align: center;
      font-weight: bold;
      border-radius: 30px;
      margin: 0 5px;
      padding: 10px 0;
    }

    .highlights-description{
      padding: 15px;
      border-radius: 30px;
      margin: 10px;
    }
  }
}
